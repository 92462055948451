










import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../_abstract'

import {
  GlobalSearchResultsModule,
  GlobalSearchResultsModuleContent
} from '../GlobalSearchResults.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({
  name: 'GlobalSearchResultsModuleForm'
})
export class GlobalSearchResultsModuleForm extends AbstractModuleForm<GlobalSearchResultsModule> {
  protected initialContent: GlobalSearchResultsModuleContent = {}
}
export default GlobalSearchResultsModuleForm
